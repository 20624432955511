@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,500;0,700;1,300;1,500;1,700&display=swap');

* {
  position: relative;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  user-select: none;
}

html {
  overflow-x: hidden;
  font-size: 16px;
}

html,
body {
  width: 100%;
  min-width: 100vw;
  max-width: 100vw;
  color: #333;
  background: rgb(240, 240, 240);
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
}

a {
  color: inherit;
  text-decoration: none;
}


/* width */
.scrollbar::-webkit-scrollbar {
  width: 8px;
  max-width: 8px;
  margin: 0;
  padding: 0;
  border-radius: 4px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: inherit;
  margin: 0;
  padding: 0;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #33333333;
  margin: 0;
  padding: 0;
  border-radius: 4px;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #33333377;
}

input::placeholder {
  color: #bbb;
}